@use "../design";

//* -- Left part of the login page -- *//

// Grid item
.login-item-left {
  background-color: map-get(design.$colors, "ciq-dark-gray");
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 0px 5vh 5vh 0px;
}

// Design elements

.login-logo {
  min-width: 80px;
  max-width: 70%;
  width: 220px;
  height: auto;
}

.login-headline {
  color: map-get(design.$colors, "ciq-white");
  font-weight: map-get(design.$font-weights, "Light");
  font-size: 22px;
  padding-top: 10px;
}

.login-subheadline {
  color: map-get(design.$colors, "ciq-white");
  font-weight: map-get(design.$font-weights, "Light");
  font-size: 14px;
  padding-top: 150px;
}

.login-text {
  color: map-get(design.$colors, "ciq-white");
  font-weight: map-get(design.$font-weights, "Light");
  font-size: 12px;
}

//Footer 
.login-item-left-footer {
  position: absolute;
  width: 80%;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

//* -- Right part of the login page -- *//

// Grid item
.login-item-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Login form
.login-form-headline {
  color: map-get(design.$colors, "ciq-dark-gray");
  font-weight: map-get(design.$font-weights, "Bold");
  font-size: 30px;
  margin: 0;
}

.login-form-label {
  font-size: 14px;
  font-weight: map-get(design.$font-weights, "Regular");
  margin-top: 30px;
}

.login-form-feedback {
  font-size: 12px;
  font-weight: map-get(design.$font-weights, "Regular");
  margin-top: 50px;
}

.login-form-hint {
  font-size: 12px;
  margin: 0;
}

.login-form-hint:hover {
  cursor: pointer;
}

// Error text when loin fails
.login-error-text {
  color: map-get(design.$colors, "ciq-error-red");
  margin: 0;
  padding-bottom: 10px;
  text-align: center;
  font-weight: map-get(design.$font-weights, "Medium");
}

.login-error-text-hidden {
  display: none;
}

// User interaction section
.login-form-ui-row {
  padding: 10px 0 0 0;
}
