$font-weights: (
  "Thin": 100,
  "Extra-light": 200,
  "Light": 300,
  "Regular": 400,
  "Medium": 500,
  "Semi-bold": 600,
  "Bold": 700,
  "Extra-Bold": 800,
  "Black": 900,
);

$colors: (
  "ciq-green-light": #09d87a,
  "ciq-green-dark": #0ee077,
  "ciq-white": #ecf0f1,
  "ciq-light-gray": #bdbdbd,
  "ciq-dark-gray": #212121,
  "ciq-error-red": #ef5350,
);

$font-sizes: (
  "h1": 55px,
  "h2": 32px,
  "h3": 21px,
  "h4": 19px,
  "h5": 17px,
  "h6": 15px,
  "Regular": 12px,
  "Small": 9px,
);

$breakpoints: (
  "xs": 0px,
  "sm": 601px,
  "md": 961px,
  "lg": 1281px,
  "xl": 1921px,
);

@mixin gridContainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin horizontalDivider {
  background-color: map-get($colors, "ciq-green-light");
  width: 400px;
  max-width: 80%;
  height: 5px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 50px;
}

@mixin xs {
  @media (min-width: map-get($breakpoints, "xs")) {
    @content;
  }
}

@mixin sm {
  @media (min-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin md {
  @media (min-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map-get($breakpoints, "xl")) {
    @content;
  }
}

@mixin typoH1 {
  @include xs {
    font-size: 23px;
  }
  @include sm {
    font-size: 25px;
  }
  @include md {
    font-size: 37px;
  }
  @include xl {
    font-size: 52px;
  }
  font-weight: map-get($font-weights, "Regular");
  text-shadow: 1px 1px 10px map-get($colors, "ciq-dark-gray");
  text-align: center;
  color: map-get($colors, "ciq-dark-grey" );
}

@mixin typoH2 {
  @include xs {
    font-size: 16px;
  }
  @include sm {
    font-size: 18px;
  }
  @include md {
    font-size: 20px;
  }
  @include xl {
    font-size: 22px;
  }
  font-weight: map-get($font-weights, "Regular");
  color: map-get($colors, "ciq-dark-grey" );
}

@mixin typoH3 {
  @include xs {
    font-size: 14px;
  }
  @include sm {
    font-size: 16px;
  }
  @include md {
    font-size: 18px;
  }
  @include xl {
    font-size: 20px;
  }
  font-weight: map-get($font-weights, "Light");
  color: map-get($colors, "ciq-dark-grey" );
}

@mixin typoH4 {
  @include xs {
    font-size: 13px;
  }
  @include md {
    font-size: 15px;
  }
  font-weight: map-get($font-weights, "Light");
  color: map-get($colors, "ciq-dark-gray");
}

@mixin typoH5 {
}

@mixin typoH6 {
}

@mixin typoRegular {
  @include xs {
    font-size: 13px;
  }
  @include md {
    font-size: 15px;
  }
  font-weight: map-get($font-weights, "Light");
  color: map-get($colors, "ciq-dark-gray");
}

@mixin typoSmall {
}
