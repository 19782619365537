@use '../design';

//* Grid system *//
.edgedeviceadddialog-container {
  @include design.gridContainer;
}

.edgedeviceadddialog-item-left {
  grid-column-end: span 5;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.edgedeviceadddialog-item-right {
  grid-column-end: span 7;
  display: flex;
  align-items: center;
}

//* Input area *//
.edgedeviceadddialog-input-description {
  margin: 0;
}

.edgedeviceadddialog-select-option {
}

//* Error Text *//
.edgedeviceadddialog-error-text {
  color: map-get(design.$colors, "ciq-error-red");
  padding-top: 20px;
  margin: 0;
  text-align: center;
  font-weight: map-get(design.$font-weights, "Medium");
}

.edgedeviceadddialog-error-text-hidden {
  display: none;
}
