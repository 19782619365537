@use '../design';

//* Grid system *//
.userdialog-container {
  @include design.gridContainer;
}

.userdialog-item-left {
  grid-column-end: span 16;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}


.userdialog-item-right {
  grid-column-end: span 16;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
//* Input area *//
.userdialog-input-description {
  margin: 0;
}

.userdialog-select-option {
}
.userdialog-header {
  width: 100%;
  color: 0 20px 25px -5px rgba(0, 0, 0, 0.1)
}

//* Error Text *//
.userdialog-error-text {
  color: map-get(design.$colors, "ciq-error-red");
  padding-top: 20px;
  margin: 0;
  text-align: center;
  font-weight: map-get(design.$font-weights, "Medium");
}

.userdialog-error-text-hidden {
  display: none;
}
.userdialog-headline {
  @include design.typoH3;
  margin: 0;
  padding: 0px 0px 25px 0px;
}
