@use '../design';

#connectiqtable-card {
  width: 100%;
  margin: 0px auto;
  border-radius: 30px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
}

.connectiqtable-card-content {
  width: 100%;
  height: 100%;
}

.connectiqtable-card-headline {
  @include design.typoH3;
  margin: 0;
  padding: 0px 0px 25px 0px;
}

.connectiqtable-card-table-wrapper {
  width: 90%;
  margin: auto;
  padding: 25px 0px 25px 0px;
}
