@use '../design';

#pooltable-card {
  width: 100%;
  margin: 0px auto;
  border-radius: 30px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
}

.pooltable-card-content {
  width: 100%;
  height: 100%;
}

.pooltable-card-headline {
  @include design.typoH3;
  margin: 0;
  padding: 0px 0px 25px 0px;
}

.pooltable-card-table-wrapper {
  width: 90%;
  margin: auto;
  padding: 25px 0px 25px 0px;
}

.pooltable-modal {
  border-radius: 50px;
}

.pooltable-inputContainer {
  @include design.gridContainer;
}

.pooltable-inputItem-left {
  grid-column-end: span 5;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}

.pooltable-inputItem-right {
  grid-column-end: span 7;
  display: flex;
  align-items: center;
}
.pooltable-input-description {
  margin: 0;
}
