@use '../design';

.whatsnewmodal-headline {
  font-size: 14px;
  font-weight: map-get(design.$font-weights, "Medium");
}

.whatsnewmodal-text-headline {
  font-size: 14px;
  font-weight: map-get(design.$font-weights, "Medium");
}

.whatsnewmodal-text {
  font-size: 14px;
  font-weight: map-get(design.$font-weights, "Regular");
}

.xxxx .ant-modal-content,
.xxxx .ant-modal-content .ant-modal-header {
  border-radius: 30px;
}
