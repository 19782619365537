@use "../design";

//* -- Header -- *//
.dashboard-header {
  background-color: map-get(design.$colors, "ciq-dark-gray");
}

.dashboard-header-logo {
  height: 25px;
  width: auto;
}

// Header avatar menu
.dashboard-header-menu {
  margin: 10px;
}
.dashboard-header-menu-icon {
  font-size: 14px;
  display: inline;
}
.dashboard-header-menu-logout-icon {
  font-size: 14px;
  display: inline;
  color: map-get(design.$colors, "ciq-error-red");
}

.dashboard-header-menu-text {
  font-size: 14px;
  display: inline;
  padding: 0px 0px 0px 10px;
}
.dashboard-header-menu-logout-text {
  font-size: 14px;
  display: inline;
  padding: 0px 0px 0px 10px;
  color: map-get(design.$colors, "ciq-error-red");
}

.dashboard-header-avatar-text {
  color: map-get(design.$colors, "ciq-white");
  font-size: 14px;
  display: inline;
  margin: 0;
  padding: 0px 10px;
}

#dashboard-header-avatar {
  background-color: map-get(design.$colors, "ciq-green-dark");
  color: map-get(design.$colors, "ciq-dark-gray");
}

.dashboard-sidebar {
  width: 100%;
  max-width: 200px;
  margin: auto;
  padding: 20px 0px 50px 0px;
}
//* -- Content -- *//
.dashboard-content-wrapper {
  width: 80%;
  max-width: 1500px;
  margin: auto;
  padding: 20px 0px 50px 0px;
}

.dashboard-topic-headline {
  @include design.typoH2;
  margin: 0;
  padding: 25px 0px;
}

//* -- Footer -- *//
.dashboard-footer-text {
  font-weight: map-get(design.$font-weights, "Light");
  font-size: 13px;
}